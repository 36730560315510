
.org {
    &__head {
        width: 100%;
        height: 42px;
        background-color: #666;
        font-size: 1.6rem;
        font-weight: 700;
        padding: 10px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        position: relative;
        &::before {
            content: "";
            display: none;
            position: absolute;
            width: 24px;
            height: 1px;
            background-color: #ddd;
            top: 50%;
            right: 0;
            transform: translate(100%, -50%);
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .org {
        &__head {
            height: 48px;
            font-size: 1.8rem;
            margin-bottom: 24px;
            &::before {
                display: block;
            }
        }
        > [class*="col"]:last-child {
            .org__head::before {
                display: none;
            }
        }
    }
}
@media (min-width: 1200px) {
}

.board-list {
    position: relative;
    border-top: 0;
}

.board-list::before,
.board-list::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    background-color: #111;
}
.board-list::before {
    top: 0;
}
.board-list::after {
    bottom: 0;
}

.board-list tbody tr:last-child {
    border-bottom: 0;
}

.board-list thead tr {
    background-color: #f5f5f5;
}
.board-list tbody tr {
    cursor: auto;
}
.board-list tbody tr td:last-child {
    text-align: left;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .board-list tr th {
        color: #111;
        font-weight: 500;
        font-size: 1.7rem;
    }
    .board-list tr th,
    .board-list tr td {
        height: 50px;
        padding: 10px 24px;
    }
    .board-list tr *:not(:last-child) {
        border-right: 1px solid var(--border-color);
    }
    .board-list__tit {
        text-align: center;
    }
}
@media (min-width: 1200px) {
}
