
.org__link{
    min-height: 42px;
    padding: 12px;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    word-break: keep-all;
    color: #666;
    font-size: 1.4rem;
    text-align: center;
    transition: 0.1s ease-out;
    .v-icon{
        color: #666;
        transition: 0.1s ease-out;
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
    .org__link{
        flex-direction: row;
        text-align: left;
    }
}
@media (min-width:1024px){
    .org__link{
        min-height: 48px;
        padding: 2px 12px;
        font-size: 1.6rem;
        flex-direction: row;
        text-align: left;
    }
    .org__link:not(.org__link--disabled):hover{
        background-color: var(--v-secondary-base);
        color: #fff;
        .v-icon{
            color: #fff;
        }
    }
}
@media (min-width:1200px){
}

